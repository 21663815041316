import { Global, css } from '@emotion/react';
import { COLORS } from 'styles';

export const globalStyles = (
  <Global
    styles={css`
      :root {
        --qr-global-font-family-primary: 'Rubik', sans-serif;
        --qr-global-font-family-secondary: 'Red Hat Display', sans-serif;

        --qr-global-font-weight-default: 400;
        --qr-global-font-weight-medium: 500;
        --qr-global-font-weight-bold: 700;

        --qr-global-font-size-super-small: 12px;
        --qr-global-font-size-small: 14px;
        --qr-global-font-size-default: 16px;

        --qr-global-font-size-header-default: 18px;
        --qr-global-font-size-header-medium: 20px;
        --qr-global-font-size-header-large: 22px;
        --qr-global-font-size-header-section: 26px;
        --qr-global-font-size-header-section-large: 30px;
        --qr-global-font-size-header-section-extra-large: 38px;

        --qr-global-font-size-button-default: 18px;
        --qr-global-font-size-button-medium: 20px;
        --qr-global-font-size-button-large: 22px;

        --qr-global-color-white: ${COLORS.WHITE};
        --qr-global-color-black: ${COLORS.MAIN_BLACK};
        --qr-global-color-blue: ${COLORS.BLUE500};
        --qr-global-color-blue-hover: ${COLORS.BLUE_HOVERED};
        --qr-global-color-navy: ${COLORS.NAVY};
        --qr-global-color-red: ${COLORS.RED500};
        --qr-global-color-yellow: ${COLORS.YELLOW500};
        --qr-global-color-yellow-hover: ${COLORS.YELLOW_HOVERED};
        --qr-global-color-main-background: ${COLORS.MAIN_BACKGROUND};
        --qr-global-color-icon-grey: ${COLORS.ICON_GREY};
        --qr-global-color-dash-grey: ${COLORS.DASH_GREY};
        --qr-global-color-lightgrey: ${COLORS.LIGHTGREY};
        --qr-global-color-lightgrey--prices: ${COLORS.BORDER_LIGHTGREY_PRICES};

        --qr-global-color-border-form-element: ${COLORS.FORM_ELEMENT_BORDER};
        --qr-global-color-focus-form-element: ${COLORS.FORM_ELEMENT_FOCUS};
        --qr-global-color-border-lightgrey: ${COLORS.BORDER_LIGHTGREY};
        --qr-global-color-border-dashed-lightgrey: ${COLORS.BORDER_DASHED_LIGHTGREY};

        --qr-global-color-success: ${COLORS.SUCCESS_COLOR};

        --qr-global-color-error: ${COLORS.ERROR};
        --qr-global-color-paused: ${COLORS.PAUSED_COLOR};

        --qr-global-color-text-primary: ${COLORS.PRIMARY_TEXT};
        --qr-global-color-text-secondary: ${COLORS.SECONDARY_TEXT};
        --qr-global-color-text-inactive: ${COLORS.INACTIVE_TEXT};

        --qr-global-color-button-primary: ${COLORS.BUTTON_PRIMARY};
        --qr-global-color-button-primary-hover: ${COLORS.PRIMARY_HOVER};
        --qr-global-color-button-secondary: ${COLORS.BUTTON_SECONDARY};
        --qr-global-color-button-secondary-hover: ${COLORS.SECONDARY_HOVER};
        --qr-global-color-button-inactive: ${COLORS.INACTIVE_BUTTON};
        --qr-global-color-button-inactive-hover: ${COLORS.INACTIVE_BUTTON_HOVER};

        --qr-global-color-review-avatar-1: ${COLORS.REVIEV_AVATAR_COLOR1};
        --qr-global-color-review-avatar-2: ${COLORS.REVIEV_AVATAR_COLOR2};
        --qr-global-color-review-avatar-3: ${COLORS.REVIEV_AVATAR_COLOR3};

        --qr-global-color-blog-bullet: ${COLORS.BLOG_BULLET_COLOR};
        --qr-global-color-illustrated-qr-code: ${COLORS.ILLUSTRATED_QRCODE};
      }
      body.no-scroll {
        overflow: hidden;
      }

      .stripeInput {
        padding: 15px 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        box-sizing: border-box;
        height: 50px;
        border: 1px solid #d9dce1;
        border-radius: 6px;
      }

      .StripeElement--focus {
        border-color: rgba(63, 126, 247, 0.36);
      }

      .StripeElement--invalid {
        border-color: ${COLORS.RED500};
      }

      @media screen and (max-width: 530px) {
        .stripeInput {
          height: 45px;
          padding: 13px 15px 12px 15px;
        }

        .stripeInput > .InputElement {
          font-size: 14px;
        }
      }

      .stripeInput :focus {
        border: 2px solid rgba(63, 126, 247, 0.36);
      }

      .stripeInput :hover {
        border: 2px solid rgba(63, 126, 247, 0.36);
      }
      .stripeInput :active {
        border: 2px solid rgba(63, 126, 247, 0.36);
      }

      .MuiClockPicker-root {
        padding: 14px;
      }

      .MuiClockPicker-root div[role='listbox']:focus {
        outline: none !important;
      }

      .MuiClockPicker-root span.MuiTypography-caption {
        font-weight: 500;
      }

      #lokalise-live-js-panel.flip {
        top: 0;
      }

      #lokalise-live-js-panel.flip .lokalise-live-js-logo {
        transform: rotateZ(180deg);
      }

      .lokalise-live-js-logo {
        transition: transform 0.25s;
        cursor: pointer;
      }

      .lokalise-live-js-edit-icon {
        pointer-events: all;
        display: inline-block !important;
      }

      .inline {
        display: inline;
      }

      :root {
        scrollbar-color: #aec0ea rgba(93, 130, 213, 0.2);
        scrollbar-width: thin;
      }

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        max-height: 40px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #aec0ea;
        border-radius: 8px;
        max-height: 40px;
      }

      ::-webkit-scrollbar-track {
        background-color: rgba(93, 130, 213, 0.2);
        border-radius: 8px;
      }

      ::-webkit-scrollbar-thumb:horizontal:hover,
      ::-webkit-scrollbar-thumb:vertical:hover {
        background-color: #5d82d5;
        max-height: 40px;
      }

      ::-webkit-scrollbar-thumb:horizontal:active,
      ::-webkit-scrollbar-thumb:vertical:active {
        background-color: #5d82d5;
        max-height: 40px;
      }

      /* Box sizing rules */
      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }

      /* Remove default margin */
      body,
      h1,
      h2,
      h3,
      h4,
      p,
      figure,
      blockquote,
      dl,
      dd,
      ul {
        margin: 0;
        padding: 0;
      }

      ul,
      ol {
        list-style: none;
      }

      /* Set core root defaults */
      html:focus-within {
        scroll-behavior: smooth;
      }

      html {
        min-height: 100%;
        height: 100%;
      }

      /* Set core body defaults */
      body {
        text-rendering: optimizeSpeed;
        font-family: 'Rubik', sans-serif;
        line-height: 1.3;
        font-weight: 400;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Rubik', sans-serif;
        color: #1e1d20;
        min-height: 100%;
        height: 100%;
        letter-spacing: 0;
      }

      /* A elements that don't have a class get default styles */
      a:not([class]) {
        text-decoration-skip-ink: auto;
      }

      /* Make images easier to work with */
      img,
      svg,
      picture {
        display: block;
      }

      /* Inherit fonts for inputs and buttons */
      input,
      button,
      textarea,
      select {
        font: inherit;
        background: none;
        padding: 0;
        margin: 0;
      }

      button {
        border: none;
        cursor: pointer;
      }

      /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
      @media (prefers-reduced-motion: reduce) {
        html:focus-within {
          scroll-behavior: auto;
        }

        *,
        *::before,
        *::after {
          animation-duration: 0.01ms !important;
          animation-iteration-count: 1 !important;
          transition-duration: 0.01ms !important;
          scroll-behavior: auto !important;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      .select-component-paper {
        transition: none !important;
      }

      #__next {
        min-height: 100%;
        height: 100%;
      }
    `}
  />
);
