import { join } from 'utils/index';
import { QRCodeUrlEnum } from 'types/qrCodes';

export enum QR_VIEW_MODES {
  PREVIEW = 'preview',
  PERMANENT = 'view',
}

export const BASE_LINKS = {
  TEMPLATE_VIEW: '/qr',
} as const;

export const LINKS = {
  // Public only
  ROOT: '/',
  FLOW: '/flow',
  LOGIN: '/login',
  LOGIN_2FA: '/login/2fa',
  SIGN_UP: '/sign-up',
  SIGN_UP_MODAL: '/sign-up-modal',
  RECOVER_PASSWORD: '/recover-password',
  CHECK_IN_BOX: '/check-in-box',
  CREATE_NEW_PASSWORD: '/reset-password/:id',
  EXPIRED_RESET_PASSWORD_LINK: '/expired-reset-password-link',
  QR_SIGN_UP: '/sign-up',
  PRICES: '/prices',
  CANCEL_SUBSCRIPTION: '/cancel-subscription',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  INMOBI: '/inmobi/welcome',

  // Public
  QR_CODE_VIEW: join(BASE_LINKS.TEMPLATE_VIEW, '/:id'),
  TEMPLATE_VIEW: join(BASE_LINKS.TEMPLATE_VIEW, '/:id/:mode'),
  BUILDER_BASE: '/generator',
  BUILDER_BASE_CANONICAL: '/qr-code-generator',
  BUILDER: '/generator/*',
  TERMS: '/terms-of-use',
  POLICY: '/privacy-policy',
  GDPR: '/gdpr',
  RESET_PASSWORD: '/qr',
  QR_SCAN: '/qr',
  ABOUT: '/about-us',
  BLOG: '/blog',
  INDUSTRIES: '/industries',
  ALT_LANDING_V1: '/home',
  ALT_LANDING_V2: '/create',
  ALT_LANDING_V3: '/start',
  FLOW_LANDING_V1: '/flow',
  FLOW_LANDING_V2: '/flow2',
  FLOW_LANDING_V3: '/flow3',

  SUBSCRIPTION_PRICING: '/pricing',
  SUBSCRIPTION_PLANS: '/plans',
  UPGRADE: '/upgrade',
  SUBSCRIPTION_ALT: '/altpricing',
  SUBSCRIPTION_CARD: '/checkout',
  SUBSCRIPTION_SUCCESS: '/checkout/success',
  SUBSCRIPTION_FLOW_CARD: '/checkout-upgrade',
  SUBSCRIPTION_FLOW_SUCCESS: '/checkout-upgrade/success',
  SUBSCRIPTION_FREEMIUM_CARD: '/checkout-plans',
  SUBSCRIPTION_FREEMIUM_SUCCESS: '/checkout-plans/success',
  REGISTRATION_SUCCESS: '/sign-up/success',

  NOT_FOUND: '/404',
  FORBIDDEN: '/403',

  // Private links
  CABINET: '/cabinet',
  CABINET_ANALYTICS: '/cabinet/analytics',
  CABINET_QR_CODES: '/cabinet/qr-codes',
  CABINET_ACCOUNT: '/cabinet/account',
  CABINET_BILLING: '/cabinet/billing',

  // SEO canonicals
  SEO_CANONICAL_ROOT: process.env.NEXT_PUBLIC_HOST,
};

export const BUILDER_TEMPLATES_LINKS = {
  URL: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.URL}`,
  VCARD: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.V_CARD}`,
  BUSINESS_PAGE: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.BUSINESS_PAGE}`,
  APP: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.APP}`,
  VIDEO: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.VIDEO}`,
  MENU: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.MENU}`,
  PDF: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.PDF}`,
  FACEBOOK: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.FACEBOOK}`,
  PLAIN_TEXT: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.PLAIN_TEXT}`,
  WIFI: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.WIFI}`,
  IMAGES: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.IMAGES}`,
  SOCIAL_MEDIA: `${LINKS.BUILDER_BASE}/${QRCodeUrlEnum.SOCIAL_MEDIA}`,
} as const;

export const BUILDER_TEMPLATES_WP_LINKS = {
  URL: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.URL}`,
  VCARD: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.V_CARD}`,
  BUSINESS_PAGE: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.BUSINESS_PAGE}`,
  APP: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.APP}`,
  VIDEO: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.VIDEO}`,
  MENU: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.MENU}`,
  PDF: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.PDF}`,
  FACEBOOK: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.FACEBOOK}`,
  PLAIN_TEXT: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.PLAIN_TEXT}`,
  WIFI: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.WIFI}`,
  AIQR: 'ai-qr-code',
  IMAGES: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.IMAGES}`,
  SOCIAL_MEDIA: `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeUrlEnum.SOCIAL_MEDIA}`,
} as const;

export const SOCIAL_MEDIA = {
  facebook: {
    title: 'Facebook',
    url: 'https://www.facebook.com/myqrcodecom',
    icon: '/images/social/facebook.svg',
  },
  linkedin: {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/my-qr-code/',
    icon: '/images/social/linkedin.svg',
  },
};

export const MAX_QR_CODE_NAME_LENGTH = 50;

export const DEFAULT_IMAGE_SIZE_RESTRICTION_IN_MEGABYTES = 5;

export const DEFAULT_VIDEO_SIZE_RESTRICTION_IN_MEGABYTES = 100;

export const DEFAULT_PDF_SIZE_RESTRICTION_IN_MEGABYTES = 20;

export const QRCODE_RASTERIZED_FORMAT_DOWNLOAD_DESIRED_WIDTH = 1200;

export const GOOGLE_MAPS_LINK_BASE_URL = 'https://www.google.com/maps/place/';

export const BUILDER_QUERY_PARAMETERS = {
  PREVIEW_QR_ID: 'id',
  PERMANENT_QR_ID: 'target',
  EDIT_MODE: 'edit',
  PREVIEW_MODE: 'preview',
} as const;

export const BUILDER_EDIT_MODES = {
  CONTENT: 'content',
  TYPE: 'type',
};

export const BUILDER_EDIT_MODES_ARRAY = Array.from(Object.values(BUILDER_EDIT_MODES));

export const ENV = process.env.NODE_ENV;

export const MAX_FREE_SCANS = 20;
export const DATE_DB_FORMAT = 'MMM dd, yyyy';
export const DATE_EXPIRE_FORMAT = 'MMMM dd, yyyy';
export const DATE_DB_FORMAT_MOBILE = 'd.MM.Y';
export const PAYMENT_DETAIL_PAYPAL = 'Paypal';
export const STRIPE_WALLETS = ['Apple Pay', 'Google Pay'] as const;
export const STRIPE_WALLETS_MAP = {
  apple_pay: STRIPE_WALLETS[0],
  google_pay: STRIPE_WALLETS[1],
};

export const FLOW_SEARCH_PARAM = 'flow';
export const QR_CREATION_SEARCH_PARAM = 'qr_creation';

export const CAN_USE_DOM = typeof window !== 'undefined' && window.document && window.document.createElement;

export const LOKALIZE_EDITOR_SCRIPT_SRC = [
  'https://app.lokalise.com/live-js/script.min.js?',
  new Date().getTime(),
].join('');

export const LOKALISE_PROJECT_ID = process.env.REACT_APP_LOKALISE_PROJECT_ID || '';

export const FAQ_BLOCK_ID = 'faq_block';
export const REACT_APP_FAQ_WP_API_ENDPOINT = process.env.REACT_APP_FAQ_WP_API_ENDPOINT || '';

export const CONTACT_US_EXTERNAL_URL = 'https://wordpress.myqrcode.com/contact-us/';
export const AUTH_COOKIE_PARAM = 'auth_token';
export const REFRESH_PAGE_COOKIE = 'refresh-page';
export const TEMPLATE_LINK = LINKS.BUILDER_BASE;

export const DISABLED_ROUTE_URL = '/qr/disabled';
// Paypal
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || '';
export const PAYPAL_NEW_ACCOUNTS_CREATION_DISABLED =
  process.env.REACT_APP_PAYPAL_NEW_ACCOUNTS_CREATION_DISABLED === 'true';

export const ALLOW_AUTOMATED_LANGUAGE_DETECTION = process.env.NEXT_ALLOW_AUTOMATED_LANGUAGE_DETECTION === 'true';

export const FORCED_DEFAULT_LOCALE_ROUTES = [LINKS.ALT_LANDING_V1, LINKS.ALT_LANDING_V2, LINKS.ALT_LANDING_V3] as const;

export const ENABLED_AB_TESTING_PAGE_URLS = process.env.NEXT_PUBLIC_ENABLED_AB_TESTING_PAGE_URLS?.split(',') || [];

export const NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID;
